<template>
    <!-- 德育考核 —> 指标管理 -->
    <div class="target-manage">
        <div class="filter-wrap">
            <filter-data
                :formData="formData"
                :closeWidth="170"
                margin-bottom="0px"
                @clickBtn="clickBtn"
                borderRadius="0px 4px 4px 4px"
            ></filter-data>
            <div class="button-line" v-has-permi="['moralmanage:moraldocument:rule:add']"></div>
            <el-button
                type="primary"
                style="margin-left: 0px;"
                v-has-permi="['moralmanage:moraldocument:rule:add']"
                @click="handleAdd()"
                >添加指标</el-button
            >
        </div>
        <div class="table-wrapper">
            <table-data
                ref="table"
                v-loading="loadingTable"
                :config="tableConfig"
                :tableData="table_data"
                :toTop="false"
            >
                <template v-slot:ruleName="{data}">
                    <img
                        :src="
                            data.ruleType === '2' ? honourIcon : smileDownIcon
                        "
                        alt=""
                        class="table-type-icon"
                    />
                    <span
                        v-if="data.behaviorList.length > 0"
                        @click="clickName(data)"
                        class="rule-name-btn"
                        >{{ data.ruleName }}</span
                    >
                    <span v-else>{{ data.ruleName }}</span>
                </template>
                <template v-slot:operation="{data}">
                    <el-button
                        v-has-permi="['moralmanage:moraldocument:rule:edit']"
                        type="text"
                        @click="handleEdit(data)"
                    >
                        编辑
                    </el-button>
                    <el-button
                        v-has-permi="[
                            'moralmanage:moraldocument:rule:addbehavor',
                        ]"
                        type="text"
                        v-if="
                            !data.children ||
                            (data.children &&
                                data.children.length === 0)
                        "
                        @click="handleAddAction(data)"
                    >
                    添加行为
                    </el-button>
                    <el-button
                        v-has-permi="[
                            'moralmanage:moraldocument:rule:addsub',
                        ]"
                        type="text"
                        v-if="data.behaviorList.length === 0"
                        @click="handleAddChild(data)"
                    >
                    添加下级
                    </el-button>
                    <el-button
                        type="text"
                        v-has-permi="[
                            'moralmanage:moraldocument:rule:del',
                        ]"
                        @click="handleDelete(data)"
                    >
                    删除
                    </el-button>
                </template>
            </table-data>
        </div>
        <!-- 添加指标 -->
        <dialog-wrapper
            :dialog-obj="dialogObj"
            @handleClose="handleClose"
            class="simple-dialog-form"
        >
            <div class="common-form">
                <el-form
                    ref="formRef"
                    :model="ruleForm"
                    :rules="rules"
                    labelWidth="auto"
                >
                    <el-form-item
                        label="德育类型"
                        prop="ruleType"
                    >
                        <el-radio
                            v-model="ruleForm.ruleType"
                            label="1"
                            :disabled="
                                dialogObj.title === '编辑指标' ||
                                ruleForm.parentId !== ''
                            "
                        >
                        批评
                        </el-radio>
                        <el-radio
                            v-model="ruleForm.ruleType"
                            label="2"
                            :disabled="
                                dialogObj.title === '编辑指标' ||
                                ruleForm.parentId !== ''
                            "
                        >
                        表扬
                        </el-radio>
                    </el-form-item>
                    <el-form-item
                        label="上级指标"
                        prop="parentId"
                    >
                        <el-select-tree
                            class="input-width-medium"
                            v-model="ruleForm.parentId"
                            :disabled="addChildren"
                            :data="
                                formatting(treeFilter(
                                    treeOptions,
                                    'ruleType',
                                    ruleForm.ruleType,
                                ))
                            "
                            :checkStrictly="true"
                            :props="{
                                value: 'id',
                                label: 'ruleName',
                                children: 'children',
                            }"
                            clearable
                            filterable
                            @change="handleChange"
                            style="width: 100%"
                        >
                        </el-select-tree>
                    </el-form-item>
                    <el-form-item
                        label="指标名称"
                        prop="ruleName"
                    >
                        <el-input
                            class="input-width-medium"
                            v-model="ruleForm.ruleName"
                            placeholder="请输入指标名称"
                            clearable
                            show-word-limit
                            maxlength="20"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="排序值"
                        prop="sort"
                    >
                        <el-input-number
                            v-model="ruleForm.sort"
                            :min="0"
                            placeholder="请输入排序值"
                            class="input-number"
                        ></el-input-number>
                    </el-form-item>
                    <el-form-item
                        label="指标描述"
                        prop="remark"
                    >
                        <el-input
                            class="input-width-medium"
                            v-model="ruleForm.remark"
                            type="textarea"
                            rows="5"
                            placeholder="请填写描述信息"
                            clearable
                            show-word-limit
                            maxlength="100"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div class="footer-button-group">
                    <el-button @click="handleClose">取消</el-button>
                    <el-button
                        :loading="btnLoading"
                        type="primary"
                        @click="handleSave"
                    >
                        确定
                    </el-button>
                </div>
            </div>
        </dialog-wrapper>
        <!-- 添加行为 -->
        <dialog-wrapper
            :dialog-obj="activeDialogObj"
            @handleClose="handleActionClose"
            class="simple-dialog-form"
        >
            <div class="common-form">
                <el-form
                ref="formRef2"
                :model="activeRuleForm"
                :rules="activeRules"
                labelWidth="auto"
            >
                <el-form-item
                    label="考核行为"
                    prop="ruleName"
                >
                    <el-input
                        class="input-width-medium"
                        v-model="activeRuleForm.ruleName"
                        type="textarea"
                        :rows="5"
                        resize="none"
                        placeholder="请输入考核行为名称"
                        clearable
                        show-word-limit
                        maxlength="100"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label="
                        activeDialogObj.ruleType === '2'
                            ? '加分分值'
                            : '扣分分值'
                    "
                    prop="score"
                >
                    <el-input
                        class="input-width-medium"
                        v-model="activeRuleForm.score"
                        placeholder="请输入分值"
                        maxlength="10"
                        show-word-limit
                        clearable
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="footer-button-group">
                <el-button @click="handleActionClose">取消</el-button>
                <el-button
                    :loading="btnLoading"
                    type="primary"
                    @click="handleActionSave"
                >
                    确定
                </el-button>
            </div>
            </div>
        </dialog-wrapper>
        <!-- 指标详情 -->
        <dialog-wrapper
            :dialog-obj="detailDialogObj"
            @handleClose="handleDetailClose"
            class="simple-dialog-form"
        >
            <template #title>
                <div style="display: flex; align-items: center">
                    <img
                        :src="
                            detailDialogObj.ruleType === '2'
                                ? honourIcon
                                : smileDownIcon
                        "
                        alt=""
                        class="table-type-icon"
                    />
                    <span>{{ detailDialogObj.name }}</span>
                </div>
            </template>
            <div class="action-list">
                <div class="action-list-title">
                    <span class="text1">考核行为</span>
                    <span class="text2">{{
                        detailDialogObj.ruleType === "1" ? "扣分" : "加分"
                    }}</span>
                </div>
                <el-form
                    :model="behaviorForm"
                    ref="behaviorForm"
                    class="behavior-form"
                >
                    <draggable
                        v-model="behaviorForm.list"
                        handle=".handleEl"
                        :animation="300"
                        group="actionList"
                    >
                        <div
                            v-for="(item, index) in behaviorForm.list.filter(
                                (i) => i.deleteStatus === 0,
                            )"
                            :key="index"
                            class="action-item"
                        >
                            <div class="handleEl">
                                <img
                                    :src="sortIcon"
                                    alt=""
                                    class="action-item__sort"
                                />
                                <div class="action-item__split"></div>
                            </div>
                            <el-form-item
                                :prop="`list.${index}.ruleName`"
                                :rules="{
                                    required: true,
                                    message: '考核行为名称不能为空',
                                    trigger: 'blur',
                                }"
                            >
                                <el-input
                                    v-model="item.ruleName"
                                    type="textarea"
                                    :rows="4"
                                    resize="none"
                                    class="action-item__name"
                                    maxlength="100"
                                    show-word-limit
                                    @focus="handleFocus($event)"
                                    @blur="handleBlur($event)"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                :prop="`list.${index}.score`"
                                :rules="[
                                    {
                                        required: true,
                                        message: '考核分数不能为空',
                                        trigger: 'blur',
                                    },
                                    {
                                        pattern: /^(\d+\.\d{1,1}|\d+)$/,
                                        message: '只支持. 和数字，且一位小数',
                                    },
                                ]"
                            >
                                <el-input
                                    v-model="item.score"
                                    class="action-item__score"
                                    maxlength="10"
                                    @focus="handleFocus($event)"
                                    @blur="handleBlur($event)"
                                ></el-input>
                            </el-form-item>
                            <img
                                :src="deleteIcon"
                                alt=""
                                class="action-item__delete"
                                @click="handleDeleteRule(item, index)"
                            />
                        </div>
                    </draggable>
                </el-form>
                <div class="footer-button-group">
                    <xk-button @click="handleDetailClose">取消</xk-button>
                    <xk-button
                        :loading="btnLoading"
                        type="primary"
                        @click="handleDetailSave"
                        >保存</xk-button
                    >
                </div>
            </div>
        </dialog-wrapper>
    </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
} from "common-local";
import {mapState} from "vuex";
import BackNav from "@/components/scrollWrapper/SManagerEduMoralAssessment/Sub/BackNav.vue";
import ElSelectTree from "el-select-tree";
import {formatTreeData, treeFind} from "@/libs/utils";
import draggable from "vuedraggable";
import onResize from "@/mixins/onResize";
import FilterData from "../Sub/ExpandFilter.vue";
import TableData from "@/components/scrollWrapper/Sub/TableDataLine";
import GlobalPageBack from "../Sub/GlobalPageBack/index.vue";

export default {
    name: "TargetManage",
    mixins: [onResize],
    components: {
        BackNav,
        FilterData,
        TableData,
        DialogWrapper,
        ElSelectTree,
        draggable,
        GlobalPageBack,
    },
    data() {
        return {
            btnLoading: false,
            loadingTable: true,
            listQuery: {
                schoolId: "",
                ruleName: "",
            },
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "请输入指标名称",
                        key: "ruleName",
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                    },
                    // {
                    //     type: "primary",
                    //     text: "添加指标 ",
                    //     fn: "add",
                    //     auth: ["moralmanage:moraldocument:rule:add"],
                    // },
                ],
            },

            // 表格配置
            tableConfig: {
                thead: [
                    {
                        type: "slot",
                        slotName: "ruleName",
                        fixed: false,
                        label: "指标名称",
                        prop: "ruleName",
                        align: "left",
                        tooltip: true,
                        labelWidth:'280px',
                        className:"target-name"
                    },
                    {
                        type: "function",
                        label: "类型",
                        prop: "ruleType",
                        align: "center",
                        // labelWidth:'100px',
                        callBack(row) {
                            return {
                                1: "批评",
                                2: "表扬",
                            }[+row.ruleType];
                        },
                        className: 'text-spacing-reduction'
                    },
                    {
                        type: "function",
                        label: "考核行为",
                        align:'center',
                        labelWidth:'100px',
                        prop: "behaviorNum",
                        callBack(row) {
                            return row.behaviorList?.length || "-";
                        },
                    },
                    {
                        label: "排序值",
                        prop: "sort",
                        align:'center',
                        // labelWidth:'100px',
                    },
                    {
                        label: "描述信息",
                        prop: "remark",
                        align: "left",
                        labelWidth:'280px',
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: "180",
                        slotName: "operation",
                        className: 'text-spacing-reduction'
                    },
                ],
                check: false,
                rowkey: "id",
                isExpandAll: true,
                showIndex: false,
                height: "",
                rowClassNameFn: this.tableRowClassName,
                expandChange: this.handleExpandChange
            },
            table_data: [],

            dialogObj: {
                title: "",
                status: "",
                dialogVisible: false,
                width: "640px",
            },
            ruleForm: {
                id: "",
                schoolId: "",
                ruleType: "1",
                parentId: "",
                ruleName: "",
                sort: "",
                remark: "",
                type: "1",
            },
            rules: {
                ruleType: [
                    {
                        required: true,
                        message: "请选择德育类型",
                        trigger: ["change", "blur"],
                    },
                ],
                ruleName: [
                    {
                        required: true,
                        message: "请输入指标名称",
                        trigger: ["change", "blur"],
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: "请输入排序值",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            treeOptions: [],

            activeDialogObj: {
                ruleType: "",
                title: "添加行为",
                dialogVisible: false,
                width: "640px",
            },
            activeRuleForm: {
                schoolId: "",
                parentId: "",
                ruleName: "",
                score: "",
            },
            activeRules: {
                ruleName: [
                    {
                        required: true,
                        message: "请输入考核行为名称",
                        trigger: ["change", "blur"],
                    },
                ],
                score: [
                    {
                        required: true,
                        message: "请输入分值",
                        trigger: ["change", "blur"],
                    },
                    {
                        pattern: /^(\d+\.\d{1,1}|\d+)$/,
                        message: "只支持. 和数字，且一位小数",
                    },
                ],
            },
            behaviorForm: {
                list: [],
            },
            detailDialogObj: {
                name: "",
                ruleType: "",
                dialogVisible: false,
                width: "800px",
            },
            addChildren: false,
            maxLevel: 0,
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
        honourIcon() {
            return require("@/assets/images/moralAsstessment/honour-icon.png");
        },
        smileDownIcon() {
            return require("@/assets/images/moralAsstessment/smile-down-icon.png");
        },
        sortIcon() {
            return require("@/assets/images/moralAsstessment/sort-icon.png");
        },
        deleteIcon() {
            return require("@/assets/images/moralAsstessment/delete-icon.png");
        },
    },
    created() {
        this.initBaseData();
    },
    mounted() {
    },
    methods: {
        /**
         * @Description: 初始化数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 17:50
         */
        initBaseData() {
            this.ruleForm.schoolId = this.schoolId;
            this.listQuery.schoolId = this.schoolId;
            this.getList();
        },
        /**
         * @Description: 获取指标列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 17:41
         */
        getList() {
            this.loadingTable = false;
            this._fet(
                "/school/schoolMoralRule/listByCondition",
                this.listQuery,
            ).then((res) => {
                if (res.data.code === "200") {
                    res.data.data = res.data.data.sort((a, b) => {
                        return a.sort - b.sort;
                    });


                    let dataList = formatTreeData(
                        JSON.parse(JSON.stringify(res.data.data)),
                        "id",
                        "parentId",
                    );
                    this.dataFilter(dataList);

                    this.table_data = dataList;

                 

                    let parentList = JSON.parse(
                        JSON.stringify(
                            res.data.data.filter(
                                (i) => i.behaviorList.length === 0,
                            ),
                        ),
                    );
                    this.treeOptions = formatTreeData(
                        parentList,
                        "id",
                        "parentId",
                    );
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
       
        /**
         * @Description: 数据处理
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-7-15 11:10:09
         */
         dataFilter(data) {
            this.maxLevel = 1;
            this.getLevel(data, 1);
            console.debug(this.maxLevel, "最大层级");
            this.treeFilter2(data, 1);
            this.addEndData(data);
        },
        /**
         * @Description: 获取几级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-16 14:05:14
         */
         getLevel(data, level) {
            data.forEach((item) => {
                if (item.children && item.children.length > 0) {
                    if (this.maxLevel <= level + 1) {
                        this.maxLevel = level + 1;
                    }
                    this.getLevel(item.children, level + 1);
                }
            });
        },
        /**
         * @Description: 树形数据处理,给数据添加等级字段,是否展开字段,是否时最后一级字段
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-16 14:05:14
         */
         treeFilter2(data, level) {
            data.forEach((item) => {
                item.level = level;
                item.isOpen = level == 1 && !item.children && this.tableConfig.isExpandAll ? false : true;
                item.isLast = false;
                item.isEnd = false;
                if (item.children && item.children.length > 0) {
                    this.treeFilter2(item.children, level + 1);
                }
            });
        },
        /**
         * @Description: 给从第二级开始的最后一级并且没有下级的行数据添加isEnd
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-18 15:01:15
         */
         addEndData(data) {
            data.forEach((item) => {
                if (item.children && item.children.length > 0) {
                    this.addEndData2(item.children);
                }
            });
        },
        /**
         * @Description: 给从第二级开始的最后一级并且没有下级的行数据添加isEnd
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-18 15:01:15
         */
        addEndData2(data) {
            let last = data[data.length - 1];
            if (last.children && last.children.length > 0) {
                this.addEndData2(last.children);
            } else {
                last.isEnd = true;
            }
        },
        /**
         * @Description: 查询/重置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 17:50
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList();
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.getList();
                    break;
                // case "add": // 添加
                //     this.dialogObj.dialogVisible = true;
                //     this.dialogObj.title = "添加指标";
                //     break;
            }
        },
        /**
         * @Description: 添加指标
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-08-01 11:31:28
         */
        handleAdd(){
            this.dialogObj.title = "添加指标";
            this.dialogObj.status = 'add';
            this.dialogObj.dialogVisible = true;
        },
        /**
         * @Description: 添加下级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 17:42
         */
        handleAddChild(data) {
            this.dialogObj.title = "添加下级";
            this.dialogObj.dialogVisible = true;
            this.ruleForm.parentId = data.id;
            this.ruleForm.ruleType = data.ruleType;
            this.addChildren = true;
        },
        /**
         * @Description: 编辑指标
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 17:52
         */
        handleEdit(data) {
            this.dialogObj.title = "编辑指标";
            this.dialogObj.dialogVisible = true;
            this.dialogObj.status = 'edit';
            Object.keys(this.ruleForm).forEach((key) => {
                this.ruleForm[key] = data[key];
            });
        },
        /**
         * @Description: 删除指标
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 17:42
         */
        handleDelete(data) {
            let message = "此操作将永久删除该指标, 是否继续";
            if (data.children && data.children.length > 0) {
                message = "当前指标包含的所有下级指标将全部删除，是否继续？";
            }
            this.$confirm(message, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let list = [{id: data.id}];
                    if (data.children) {
                        this.getDeleteIds(data.children, list);
                    }
                    if (data.behaviorList) {
                        list.push(
                            ...data.behaviorList.map((i) => ({
                                id: i.id,
                            })),
                        );
                    }
                    this._fet("/school/schoolMoralRule/delete", list, {
                        isArrParams: true,
                    }).then((res) => {
                        if (res.data.code === "200") {
                            this.getList();
                            this.$message.success("删除成功");
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        getDeleteIds(children, list) {
            children.forEach((item) => {
                list.push({
                    id: item.id,
                });
                if (item.behaviorList) {
                    list.push(
                        ...item.behaviorList.map((i) => ({
                            id: i.id,
                        })),
                    );
                }
                if (item.children) {
                    this.getDeleteIds(item.children, list);
                }
            });
        },
        /**
         * @Description: 保存指标
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 17:43
         */
        handleSave() {
            this.$refs.formRef.validate((val) => {
                if (val) {
                    this.btnLoading = true;
                    this._fet("/school/schoolMoralRule/save", this.ruleForm)
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("操作成功");
                                this.getList();
                                this.handleClose();
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                            this.btnLoading = false;
                        })
                        .catch((res) => {
                            this.btnLoading = false;
                        });
                }
            });
        },
        /**
         * @Description: 关闭保存指标弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 17:45
         */
        handleClose() {
            this.dialogObj.dialogVisible = false;
            this.ruleForm = this.$options.data.call().ruleForm;
            this.ruleForm.schoolId = this.schoolId;
            this.addChildren = false;
            this.$nextTick(() => {
                this.$refs.formRef.clearValidate();
            });
        },

        /**
         * @Description: 添加行为
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/29 10:12
         */
        handleAddAction(data) {
            this.activeDialogObj.dialogVisible = true;
            this.activeDialogObj.ruleType = data.ruleType;
            this.activeRuleForm.schoolId = this.schoolId;
            this.activeRuleForm.parentId = data.id;
        },
        /**
         * @Description: 保存行为
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/29 10:12
         */
        handleActionSave() {
            this.$refs.formRef2.validate((val) => {
                if (val) {
                    this.btnLoading = true;
                    this._fet(
                        "/school/schoolMoralRule/saveBehavior",
                        this.activeRuleForm,
                    )
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("添加成功");
                                this.handleActionClose();
                                this.getList();
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                    console.log(this.activeRuleForm);
                }
            });
        },
        /**
         * @Description: 关闭添加行为弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/29 10:12
         */
        handleActionClose() {
            this.activeDialogObj.dialogVisible = false;
            this.activeDialogObj.ruleType = "";
            this.activeRuleForm = this.$options.data.call().activeRuleForm;
            this.$nextTick(() => {
                this.$refs.formRef2.clearValidate();
            });
        },

        /**
         * @Description: 编辑行为
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/29 16:08
         */
        // 修改行为
        clickName(data) {
            this.detailDialogObj.dialogVisible = true;
            let list = this._.cloneDeep(data.behaviorList);
            list.forEach((item) => {
                item.deleteStatus = 0;
            });
            this.behaviorForm.list = list.sort((a, b) => {
                return a.sort - b.sort;
            });
            this.detailDialogObj.name = data.ruleName;
            this.detailDialogObj.ruleType = data.ruleType;
        },
        handleDeleteRule(item, index) {
            this.$confirm("是否移除此项?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then((res) => {
                    this.behaviorForm.list[index].deleteStatus = 1;
                })
                .catch(() => {});
        },
        handleFocus($event) {
            let input = $event.currentTarget;
            input.style.borderColor = "#3C7FFF";
            input.style.background = "#ffffff";
        },
        handleBlur($event) {
            let input = $event.currentTarget;
            input.style.borderColor = "#F7F9FA";
            input.style.background = "#F7F9FA";
        },
        /**
         * @Description: 保存行为
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/29 16:09
         */
        handleDetailSave() {
            this.$refs.behaviorForm.validate((val) => {
                if (val) {
                    let names = this.behaviorForm.list.map((i) => i.ruleName);
                    let nameSet = new Set(names);
                    if (names.length !== nameSet.size) {
                        this.$message.warning("考核行为名称不能重复");
                        return;
                    }
                    this.behaviorForm.list.forEach((item, index) => {
                        item.sort = index;
                    });
                    this.btnLoading = true;
                    this._fet(
                        "/school/schoolMoralRule/updateBehaviorList",
                        this.behaviorForm.list,
                        {
                            isArrParams: true,
                        },
                    )
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("修改成功");
                                this.handleDetailClose();
                                this.getList();
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }
            });
        },
        /**
         * @Description: 关闭保存行为弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/29 16:09
         */
        handleDetailClose() {
            this.detailDialogObj.dialogVisible = false;
            setTimeout(() => {
                this.behaviorForm.list = [];
            }, 300);
        },
        handleChange(data) {
            this.ruleForm.ruleType = "";
            const current = treeFind(this.treeOptions, (x) => x.id === data);
            if (current) {
                this.ruleForm.ruleType = current.ruleType;
            }
        },
        treeFilter(data, findKey, findVal, subarrKey) {
            if (findVal) {
                let newData = data.filter((x) => x[findKey] === findVal);
                newData.forEach(
                    (x) =>
                        x[subarrKey] &&
                        (x[subarrKey] = this.treeFilter(
                            x[subarrKey],
                            findKey,
                            findVal,
                            subarrKey
                        )),
                );
                return newData;
            } else {
                return data;
            }
        },
        /**
         * @Description: 格式化指标数据
         * @DoWhat: 如果是编辑，则把本身已选择和下级删除
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-12-19 17:03:07
         */
        formatting (arr) {
            if (this.dialogObj.status  && this.dialogObj.status == 'edit') {
                return this.finalData(arr, this.ruleForm.id);
            } else {
                return arr;
            }
        },
        finalData (arr, id) {
            let _this = this;
            arr.map((item, index) => {
                if (item.id && item.id === id) {
                    arr.splice(index, 1)
                }

                if (item.children && item.children.length > 0) {
                    _this.finalData (item.children, id)
                }
            })
            return arr;
        },
        /**
         * @Description: 返回
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 17:50
         */
        handlerGlobalType() {
            this.$emit("changeComp", {
                name: "AssessmentList",
            });
        },
    },
};
</script>
<style scoped lang="scss">
.target-manage {
    padding-right: 10px;
}

.filter-wrap {
    width: 100%;
    // margin-top: 10px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 0 4px 4px 4px;
    margin-bottom: 10px;
    overflow: hidden;
    .filter-back {
        padding: 0;
    }

}
.table-wrapper {
    // margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
}
.simple-dialog-form {
    .footer-button-group {
        text-align: right;
    }
    // ::v-deep .el-dialog {
    //     border-radius: 8px;

    //     .el-dialog__header {
    //         background-color: #EBF1FA;
    //         border-top-right-radius: 8px;
    //         border-top-left-radius: 8px;
    //     }
    //     .el-dialog__body {
    //         padding: 40px 40px 28px 40px;
    //     }
    //
    // }
}

// ::v-deep .el-table .el-table__body .el-table__cell {
//     color: #363b40 !important;
//     padding: 14px 0;
// }

.el-dropdown-link {
    cursor: pointer;
}

.el-dropdown-menu__item {
    text-align: center;
}

.table-type-icon {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    vertical-align: middle;
    margin-bottom: 2px;
    margin-top: 4px;
}

.rule-name-btn {
    cursor: pointer;
    color: #3C7FFF;
}

.button-text {
    margin-left: 0;
    + .button-text {
        margin-left: 10px;
    }
}

.action-list {
    .action-list-title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2b2f33;
        margin-bottom: 28px;

        .text1 {
            margin-left: 70px;
        }

        .text2 {
            margin-left: 463px;
        }
    }

    .action-item {
        display: flex;
        height: 80px;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 18px;

        .handleEl {
            display: flex;
            cursor: pointer;
            align-items: center;
        }

        &__sort {
            width: 18px;
            height: 16px;
        }

        &__split {
            margin-left: 20px;
            margin-right: 16px;
            width: 1px;
            height: 24px;
            background: #e1e3e6;
        }

        &__name {
            width: 500px;
            margin-right: 8px;

            ::v-deep .el-input__inner {
                padding-right: 50px;
            }
        }

        &__score {
            width: 80px;
            margin-right: 24px;
        }

        &__name,
        &__score {
            ::v-deep .el-input__inner {
                background: #f7f9fa;
                border-color: #f7f9fa;
            }
        }

        &__delete {
            cursor: pointer;
        }

        ::v-deep .el-form-item__error {
            white-space: nowrap;
        }
    }

    .footer-button-group {
        margin-top: 30px;
    }
    .behavior-form {
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 10px;

        ::v-deep .el-textarea{
            .el-input__count{
                line-height: 12px;
                background:rgba(255,255,255,.5)
            }
        }
    }
    .behavior-form .el-form-item {
        margin-bottom: 0;

        .action-item__score {
            ::v-deep .el-input__inner {
                text-align: center;
            }
        }
    }

    ::v-deep .el-input .el-input__count-inner {
        background: transparent;
    }
}
::v-deep .el-table {
    .el-table__row .el-table__cell:first-child .cell {
        padding-left: 62px;
    }

    [class*=el-table__row--level] {
        .el-table__expand-icon {
            margin-right: 4px;

            &:hover .el-icon-arrow-right {
                background-color: #3c7fff;
            }
        }

        .el-table__expand-icon .el-icon-arrow-right {
            transform: rotate(90deg);
            color: #FFFFFF;
            font-size: 13px;
            width: 16px;
            height: 16px;
            line-height: 17px;
            text-align: center;
            background-color: #bfbfbf;
            border-radius: 50%;
            transition: rotate 0.2s ease-in;
        }

        .el-table__expand-icon--expanded .el-icon-arrow-right {
            transform: rotate(-180deg) !important;
            background-color: #3c7fff;
        }

        .el-table__placeholder {
            width: 0;
        }
    }

    .special-row {
        td {
            color: #909399 !important;

            &:nth-child(2) {
                color: #666 !important;
            }
        } 
    }
}
</style>
