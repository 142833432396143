<template>
    <div>
        <keep-alive include="AssessmentList">
            <component
                :is="currentComp"
                :student-data="studentData"
                :detailPageTitle="detailPageTitle"
                @changeComp="changeComp"
                @setPageInitTabs = "setPageInitTabs"
            ></component>
        </keep-alive>
    </div>
</template>

<script>
import AssessmentList from "@/components/scrollWrapper/SManagerEduMoralAssessment/AssessmentList.vue";
import TargetManages from "./SManagerEduTargetManage";
import SmartReviews from "@/components/scrollWrapper/SManagerEduMoralAssessment/SmartReviews.vue";
import NoticeManage from "@/components/scrollWrapper/SManagerEduMoralAssessment/NoticeManage.vue";
import ArchivesManage from "@/components/scrollWrapper/SManagerEduMoralAssessment/ArchivesManage.vue";
export default {
    name: "SManagerEduMoralAssessment",
    components: {
        AssessmentList,
        TargetManages,
        SmartReviews,
        NoticeManage,
        ArchivesManage,
    },
    data() {
        return {
            currentComp: "AssessmentList",
            studentData: {},
            detailPageTitle: ''
        }
    },
    activated () {
        this.changeComp({name: this.currentComp, data: this.studentData})
    },
    created () {
        this.$on('handlerGlobalPageBack', () => {
            this.handlerGlobalPageBack()
        })
    },
    methods: {
        changeComp(compData) {
            const { name, data } = compData
            console.log(name,data,'name')

            switch (name) {
                case 'SmartReviews':
                    this.$eventDispatch('setGlobalPageType', 'detail')
                    this.$emit('transfer',false)
                    break;
                case 'noticeManage':
                    this.detailPageTitle = '通知管理';
                    this.$eventDispatch('setGlobalPageType', 'detail')
                    this.$emit('transfer',false)
                    break;
                case 'ArchivesManage':
                    if (data.sourceType === "SmartReviews") {
                        this.detailPageTitle = `智能考核 / ${data.studentName || '学生'}的德育档案`;
                    } else {
                        this.detailPageTitle = `${data.studentName || '学生'}的德育档案`;
                    }
                    this.$eventDispatch('setGlobalPageType', 'detail')
                    this.$emit('transfer',false)
                    break;
                case 'targetManages':
                    this.$eventDispatch('setGlobalPageType', 'detail')
                    this.$emit('transfer',false)
                    break;
                default:
                    this.$eventDispatch('setGlobalPageType', 'list')
                    this.$emit('transfer',true)
                    break;
            }

            this.currentComp = name
            this.studentData = data
        },
        handlerGlobalType (compData) {
            console.log('456')
            // const { name, data } = compData;
            // this.currentComp = 'AssessmentList';
            // this.$eventDispatch('setGlobalPageType', 'list')
        },
        handlerGlobalPageBack () {
            console.log('123')
            this.changeComp({name: 'AssessmentList'})
        },
        setPageInitTabs(){
            console.log('是否执行了')
            this.$eventDispatch('setGlobalPageType', 'list')
            this.currentComp = "AssessmentList"

        }
    }
}
</script>

<style scoped>

</style>
